import {
  ApolloClient, ApolloLink, fromPromise, InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client/public/index';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { REFRESH } from '../api/query';
import {
  clearAccessTokenUser, getRoleUser, USER_LOCALSTORAGE_KEY,
} from '../helpers/user-storage';
import { SIGN_IN } from '../constants/Routes';
import { ROLE_CUSTOMER } from '../constants/employ';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../constants/general/tag-status';

const API_HOST = process.env.REACT_APP_API_URL || 'https://api-staging.yallabanana.com/graphql';

let test;

let isRefreshing = false;
let pendingRequests = [];

const setIsRefreshing = (value) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const getNewToken = async () => {
  const oldToken = localStorage.getItem(USER_LOCALSTORAGE_KEY);

  if (oldToken) {
    const { data: { refresh } } = await test.mutate({
      mutation: REFRESH,
      variables: {
        refreshToken: Cookies.get(TITLE_COOKIES),
      },
    });

    if (refresh) {
      const { accessToken, refreshToken } = refresh;
      localStorage.setItem(USER_LOCALSTORAGE_KEY, accessToken);
      localStorage.removeItem('error-invalid-token');/// затер
      Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
    }
  }
};

// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      if (
        err.message === 'Token error Token is invalid'
        || err.message === 'Token is invalid'
        || err.message === 'Invalid token'
      ) {
        resolvePendingRequests();
        localStorage.setItem('error-invalid-token', 'true');
        setIsRefreshing(false);
        const role = getRoleUser();
        clearAccessTokenUser();
        Cookies.remove(TITLE_COOKIES);
        forward(operation);
        if (role && role !== ROLE_CUSTOMER && window.location.href.includes('admin')) {
          window.location.replace(SIGN_IN);
        }
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      const status = err?.extensions?.exception?.status;
      // eslint-disable-next-line default-case
      switch (status) {
      case 401:
        if (!isRefreshing) {
          setIsRefreshing(true);
          return fromPromise(
            getNewToken().catch(() => {
              resolvePendingRequests();
              setIsRefreshing(false);
              const role = getRoleUser();
              clearAccessTokenUser();
              if (role && role !== ROLE_CUSTOMER && window.location.href.includes('admin')) {
                window.location.replace(SIGN_IN);
              }
              return forward(operation);
            }),
          ).flatMap(() => {
            resolvePendingRequests();
            setIsRefreshing(false);
            return forward(operation);
          });
        }
        return fromPromise(
          new Promise((resolve) => {
            addPendingRequest(() => resolve());
          }),
        ).flatMap(() => forward(operation));
      }
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const uploadLink = createUploadLink({
  uri: API_HOST,
  credentials: 'include',
});

test = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

const client = test;

export default client;
